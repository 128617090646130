<template>
    <my-contact></my-contact>
    <rightIcon></rightIcon>
</template>

<script>
import myContact from '@/components/myContact/myContact.vue'
import rightIcon from '@/components/right_icon/right_icon.vue'
export default {
    components: {
        myContact,
        rightIcon
    }

}
</script>

<style scoped>
</style>
