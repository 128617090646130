<template>
	<div  v-if="myData.length != 0" class="protfolioWrap" style=" text-align:center;">
		<h1 class="text-center">{{ myData[myId - 1].title }}</h1>
		<div class="imgBox col-12" v-for="(item, index) in myData[myId - 1].photo" :key="index">
			<img style="margin-top: 100px;" class="img-fluid" :src="`/img/建築作品/${myData[myId - 1].folder}${item}`" />
		</div>
	</div>
	<div @click="back" class="container"
		style="margin-top: 20px; margin-bottom: 20px;display: flex;justify-content: center;align-items: center;">
		<div class="box"
			style="position: relative;font-size: 20px; border-radius: 100%; height: 30px; width: 30px; text-align: center;border: 1px solid black;display:flex;justify-content: center;align-items: center;">
			<span style="position: absolute;top:-2px">x</span>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data() {
			return {
				myData: []
			}
		},
		props: {
			myId: {
				type: Number,
				default: 0
			}
		},
		computed: {
			list: function() {
				return '';
			}
		},
		methods: {
			back() {
				window.history.go(-1);
			}
		},
		mounted() {
			axios.get('./buildList.json').then((res) => {
				this.myData = res.data;
			})
		}
	}
</script>

<style scoped>
	* {
		font-family: "Manjari", sans-serif, "STHeiti Light", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
	}

	.protfolioWrap {
		margin-top: 80px;
		overflow-x: hidden;
	}
</style>
