<template>
	<div v-if="myData.length != 0" class="row" style=" text-align:center; margin-left: 0px; margin-right: 0px; margin-top:80px;">
		<h1 class="text-center">{{ myData[myId - 1].title }}</h1>
		<div class="imgBox col-12" v-for="(item, index) in myData[myId - 1].photo" :key="index">
			<img style="margin-top: 100px;" class="img-fluid" :src="`/img/${myData[myId - 1].folder}/${item}`" />
		</div>
	</div>
	<div @click="back" class="container"
		style="margin-top: 20px; margin-bottom: 20px;display: flex;justify-content: center;align-items: center;">
		<div class="box"
			style="position: relative;font-size: 20px; border-radius: 100%; height: 30px; width: 30px; text-align: center; ;border: 1px solid black;display:flex;justify-content: center;align-items: center;">
			<span style="position: absolute;top:-2px">x</span>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
export default {
	data() {
		return {
			myData: []
		}
	},
	mounted() {
		axios.get('./spaceList.json').then((res) => {
			this.myData = res.data;
		})
	},
	props: {
		myId: {
			type: Number,
			default: 0
		}
	},
	computed: {
		list: function () {
			return '';
		}
	},
	methods: {
		back() {
			window.history.go(-1);
		}
	},
}
</script>

<style scoped>
* {
	font-family: "Manjari", sans-serif, "STHeiti Light", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
}

@media (max-width: 992px) {
	box > i {
		position: absolute;
		top: 30%;
		left: 50%;
	}
	
	box > i::before {
		position: absolute;
		top: 40%;
		left: 50%;
	}
	
	box > i::after {
		position: absolute;
		top: 50%;
		left: 50%;
	}
}
</style>
